var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Colaboradores")]),_c('v-spacer'),(_vm.allowCreate)?_c('v-btn',{attrs:{"to":"/main/GestaoColaboradores/create","color":"primary"}},[_vm._v("Registrar")]):_vm._e()],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Somente ativos","hide-details":"auto"},on:{"click":function($event){return _vm.buscar()}},model:{value:(_vm.somenteAtivos),callback:function ($$v) {_vm.somenteAtivos=$$v},expression:"somenteAtivos"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{staticClass:"ma-1",attrs:{"clearable":"","flat":"","hide-details":"","prepend-inner-icon":"mdi-magnify","label":"Buscar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"footer-props":{ itemsPerPageOptions:[-1] },"headers":_vm.headers,"items":_vm.colaboradorItems,"search":_vm.search,"dense":"","fixed-header":"","height":"60vh","hide-default-footer":"","loading-text":"Carregando...","multi-sort":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.allowEdit)?_c('v-icon',{on:{"click":function($event){return _vm.routeEdit(item.id)}}},[_vm._v("mdi-pencil")]):_vm._e()]}},{key:"item.is_professor",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatCpfCnpj")(item.cpf))+" ")]}},{key:"item.is_active",fn:function(ref){
var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }